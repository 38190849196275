<template>
  <div>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <Dialog
        :dialog="dialog"
        :title="'Neue Position anlegen'"
        :values="invoiceCreateFields"
        :successButton="'Erstellen'"
        :icon="'mdi mdi-file-document'"
        :headLines="[]"
        :cut="4"
        @hideDialog="hideDialog"
        @emitNewData="createInvoice"
        @createEntry="startCreateCustomer($event)"
        @dataChanged="valueChanged"
    ></Dialog>
    <CreateCustomer
        :dialog="dialogCustomer"
        @hideDialog="hideDialogCustomer"
        @createCustomer="customerCreated"
    >
    </CreateCustomer>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const Dialog = () => import("@/components/dialog/FormDialogNew");
const CreateCustomer = () => import("@/components/dialog/createDialogs/CreateCustomer");

export default {
  name: "CreatePosition",
  components: {
    Dialog,
    Message,
    CreateCustomer
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('settings', {
      invoiceSettings: 'invoiceSettings'
    }),
    ...mapGetters('customer', {
      customers: 'customersForSelection',
      createdCustomerId: 'customerCreatedId'
    }),
  },
  mounted() {
      this.$store.dispatch('customer/getCustomersForSelection', {
        uid: this.user.id
      }).then(()=>{
          this.invoiceCreateFields = this.getInvoiceCreateFields()
      }).catch((err)=>{
        this.message = error(err)
      })
    this.$store.dispatch('settings/getInvoiceSettings', {
      uid: this.user.id
    }).then(()=>{
      this.invoiceCreateFields = this.getInvoiceCreateFields()
    }).catch((err)=>{
      this.message = error(err)
    })
  },
  data() {
    return {
      createFields: [],
      dialogCustomer: false,
      message: msgObj(),
      invoiceCreateFields: []
    }
  },
  props: {
    dialog: Boolean,
    customerSelection: String,
    invoiceId: String,
    openAfterCreate: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  methods: {
    hideDialog() {
      this.$emit('hideDialog')
    },
    hideDialogCustomer() {
      this.dialogCustomer = false
    },
    startCreateCustomer() {
      this.dialogCustomer = true
    },
    customerCreated() {
      this.$store.dispatch('customer/getCustomersForSelection', {
        uid: this.user.id
      }).then(() => {
        this.invoiceCreateFields = [
          {
            title: 'Bezeichnung',
            value: null,
            id: 0,
            type: 'string'
          },
          {
            title: 'Beschreibung (optional)',
            value: null,
            id: 1,
            type: 'string'
          },
          {
            title: 'Menge',
            value: 0,
            id: 2,
            type: 'number',
          },
          {
            title: 'Einheit',
            value: 'null',
            id: 3,
            type: 'string'
          },
          {
            title: 'Preis',
            value: 0,
            id: 4,
            type: 'number'
          },
          {
            title: 'Steuersatz %',
            value: null,
            id: 5,
            type: 'number'
          },

        ]
      })
    },
    createInvoice(newValues) {
      let newInvoice = this.getInvoiceFromInput(newValues)
      this.$store.dispatch('invoice/createPosition', {
        newInvoice,
        uid: this.user.id,
      }).then(() => {
            this.hideDialog()
            this.message = success('Position wurde erfolgreich angelegt.')
          }
      ).catch((err) => {
        this.message = error(err)
      })
    },
    getInvoiceCreateFields() {
      return [
        {
          title: 'Bezeichnung',
          value: null,
          id: 0,
          type: 'string'
        },
        {
          title: 'Beschreibung (optional)',
          value: null,
          id: 1,
          type: 'string'
        },
        {
          title: 'Menge',
          value: 1,
          id: 2,
          type: 'number',
        },
        {
          title: 'Einheit',
          value: 'Stk',
          id: 3,
          type: 'string'
        },
        {
          title: 'Preis',
          value: 0.00,
          id: 4,
          type: 'number'
        },
        {
          title: 'Steuersatz %',
          value: this.invoiceSettings.steuersatz,
          id: 5,
          type: 'number'
        },
      ]
    },
    getInvoiceFromInput(newValues) {
      let newInvoice = {
        title: '',
        quant: 0,
        einheit: '',
        price: 0,
        steuersatz: 0,
        invoiceId: this.invoiceId,
        sum: 0,
        description: ''
      }
      for (let value of newValues) {
        switch (value.title) {
          case 'Bezeichnung':
            newInvoice.title = value.value
            break;
          case 'Beschreibung (optional)':
            newInvoice.description = value.value
            break;
          case 'Menge':
            newInvoice.quant = value.value
            break;
          case 'Einheit':
            newInvoice.einheit = value.value
            break;
          case 'Preis':
            newInvoice.price = value.value
            break;
          case 'Steuersatz %':
            newInvoice.steuersatz = value.value
            break;
        }
      }
      let multi = 1+(newInvoice.steuersatz * 0.01)
      newInvoice.sum = (newInvoice.price*newInvoice.quant) * multi
      return newInvoice
    },

  },
}
</script>
